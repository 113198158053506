import React from "react";
import styled from "styled-components";

import { ThemeProviderProps, themeColorPicker } from "@src/theme";

const Title = styled.h1<ThemeProviderProps>`
  color: ${themeColorPicker("Foreground")};
  font-weight: bold;
  font-size: ${({ size }) => (size && `${size}rem`) || `6rem`};
  font-family: ${({ theme }) => theme.fonts.HeaderSans};
  font-weight: 800;
  margin: 0;
  line-height: 1em;
  text-align: center;
  text-transform: uppercase;
  max-width: ${({ theme }) => theme.dimensions.maxWidth};

  @media (max-width: 800px) {
    font-size: ${({ size }) => (size && `${size / 2}rem`) || `3rem`};
  }
`;

const TitleSerif = styled.span<ThemeProviderProps>`
  color: ${themeColorPicker("Foreground")};
  font-family: ${({ theme }) => theme.fonts.HeaderSerif};
  font-style: italic;
  font-weight: 100;
  text-transform: none;
  font-size: ${({ size }) => (size && `${size}rem`) || `0.6em`};
  line-height: 1.1em;
`;

const TitleSmall = styled.span<ThemeProviderProps>`
  color: ${themeColorPicker("Comment")};
  font-family: ${({ theme }) => theme.fonts.Monospace};
  text-transform: uppercase;
  font-size: ${({ size }) => (size && `${size}rem`) || `0.3em`};
  line-height: 1em;
  letter-spacing: -0.05em;
`;

const Subtitle = styled.div<ThemeProviderProps>`
  font-family: ${({ theme }) => theme.fonts.HeaderSerif};
  font-style: italic;
  font-weight: 100;
  text-transform: none;
  font-size: 1.4em;
  margin: 3rem auto;
  text-align: center;
`;

const Author = styled.h2<ThemeProviderProps>`
  border-top: 1px solid ${themeColorPicker("Selection")};
  color: ${themeColorPicker("Foreground")};
  font-family: ${({ theme }) => theme.fonts.HeaderSans};
  font-size: 1.4rem;
  font-weight: 800;
  margin: 6rem auto 4rem auto;
  text-align: center;
  text-transform: uppercase;
  max-width: ${({ theme }) => theme.dimensions.maxWidth};
`;

const AuthorWrapper = styled.div<ThemeProviderProps>`
  background: ${({ theme, inverted }) =>
    inverted ? theme.inverted.Background : theme.background};
  padding: 0 20px;
  position: relative;
  display: inline-block;
  top: -1.2rem;
`;

const ByLine = ({ children }) => (
  <Author>
    <AuthorWrapper>{children}</AuthorWrapper>
  </Author>
);

const DateWrapper = styled.div<ThemeProviderProps>`
  font-family: ${({ theme }) => theme.fonts.HeaderSerif};
  color: ${({ theme, inverted }) =>
    inverted ? theme.inverted.Foreground : theme.color.Foreground};
  font-style: italic;
  font-weight: 100;
  text-transform: none;
  font-size: 0.8em;
  margin: 0.4rem 0;
  text-align: center;
`;

const DateLine = ({ date }) => {
  const d = new Date(date);
  return (
    <DateWrapper>
      {d.toLocaleDateString("en-AU", {
        weekday: "long",
        year: "numeric",
        month: "long",
        day: "numeric",
      })}
    </DateWrapper>
  );
};

export { Title, TitleSerif, TitleSmall, Subtitle, ByLine, DateLine };
