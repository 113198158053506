import React from "react";
import styled from "styled-components";

import { ThemeProviderProps } from "@src/theme";

const Outer = styled.div<ThemeProviderProps>`
  border-top: 1px solid ${props => props.theme.color.Selection};
  margin: 4rem auto 1rem auto;
  text-align: center;
  text-transform: uppercase;
  max-width: ${props => props.theme.dimensions.mainWidth};
`;

const Inner = styled.div<ThemeProviderProps>`
  background: ${props => props.theme.background};
  color: ${props => props.theme.color.Foreground};
  padding: 0 0.6rem;
  position: relative;
  display: inline-block;
  font-size: 2rem;
  top: -1.55rem;
`;

// ❦ ❖
const HR = ({ width }) => (
  <Outer style={{ maxWidth: width }}>
    <Inner>❖</Inner>
  </Outer>
);

export default HR;
