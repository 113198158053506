import Highlight, {
  defaultProps,
  Language as HighlightLanguageOptions,
} from "prism-react-renderer";
import React from "react";
import { withTheme } from "styled-components";

import { ThemeConfig } from "@src/theme";
import { prismTheme } from "@src/theme/prism";

import {
  Pre,
  Line,
  LineNumber,
  CodeLine,
  HighlightedLine,
  HighlightedLineNumber,
  Meta,
  Language,
  Filename,
} from "./styled";

const LinesToHighlightExpression = /{([\d,-]+)}/;

const calculateLinesToHighlight = meta => {
  if (!LinesToHighlightExpression.test(meta)) {
    return () => false;
  } else {
    const lineNumbers = LinesToHighlightExpression.exec(meta)[1]
      .split(",")
      .map(v => v.split("-").map(v => parseInt(v, 10)));
    return index => {
      const lineNumber = index + 1;
      const inRange = lineNumbers.some(([start, end]) =>
        end ? lineNumber >= start && lineNumber <= end : lineNumber === start
      );
      return inRange;
    };
  }
};

const getKeyValuePairs = meta => {
  const KeyValueExpression = /(\w+)=([^\s]+)/g;
  const test = KeyValueExpression.exec(meta);
  if (typeof test === "undefined" || !Array.isArray(test)) {
    return;
  }
  return test
    .map(pair => {
      if (pair.includes("=")) {
        const [k, v] = pair.split("=");
        return {
          [k]: v,
        };
      }
    })
    .filter(Boolean)
    .reduce((acc, item) => {
      return {
        ...acc,
        [item.k]: item.v,
      };
    });
};

// Used in mdx transformed Code blocks
const MarkdownCode = ({ children, metastring, className, theme }) => {
  const shouldHighlightLine = calculateLinesToHighlight(metastring);
  const language = className.replace(/language-/, "");
  const pairs = getKeyValuePairs(metastring);

  return (
    <Highlight
      {...defaultProps}
      code={children}
      language={language}
      theme={prismTheme(theme)}
    >
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Pre className={className} style={style}>
          <Meta>
            <Language>{language}</Language>
            {pairs && pairs.filename && <Filename>{pairs.filename}</Filename>}
          </Meta>
          {tokens.map((line, i) => {
            const lineProps = getLineProps({ line, key: i });
            let LineComponent = Line;
            let LineNumberComponent = LineNumber;
            if (shouldHighlightLine(i)) {
              LineComponent = HighlightedLine;
              LineNumberComponent = HighlightedLineNumber;
            }
            return (
              <LineComponent {...lineProps} key={i}>
                <LineNumberComponent>{i + 1}</LineNumberComponent>
                {line.map((token, key) => (
                  <CodeLine {...getTokenProps({ token, key })} key={key} />
                ))}
              </LineComponent>
            );
          })}
        </Pre>
      )}
    </Highlight>
  );
};

interface CodeProps {
  source: string;
  language: HighlightLanguageOptions;
  filename: string;
}
interface CodePropsWithTheme extends CodeProps {
  theme: ThemeConfig;
}
// Mainly used in slides
const Code = ({ source, language, theme, filename }: CodePropsWithTheme) => (
  <Highlight
    {...defaultProps}
    code={source}
    language={language}
    theme={prismTheme(theme)}
  >
    {({ className, style, tokens, getLineProps, getTokenProps }) => (
      <Pre className={className} style={style}>
        <Meta>
          <Language>{language}</Language>
          {filename && <Filename>{filename}</Filename>}
        </Meta>
        {tokens.map((line, i) => {
          const lineProps = getLineProps({ line, key: i });
          let LineComponent = Line;
          let LineNumberComponent = LineNumber;
          // if (shouldHighlightLine(i)) {
          //   LineComponent = HighlightedLine
          //   LineNumberComponent = HighlightedLineNumber
          // }
          return (
            <LineComponent {...lineProps} key={i}>
              <LineNumberComponent>{i + 1}</LineNumberComponent>
              {line.map((token, key) => (
                <CodeLine {...getTokenProps({ token, key })} key={key} />
              ))}
            </LineComponent>
          );
        })}
      </Pre>
    )}
  </Highlight>
);

const MarkdownCodeWithTheme = withTheme(MarkdownCode);
const CodeWithTheme = withTheme(Code);

export {
  MarkdownCodeWithTheme as MarkdownCode,
  CodeWithTheme as Code,
  CodeProps,
};
