import styled from "styled-components";

import { ThemeProviderProps } from "@src/theme";

const Paragraph = styled.p<ThemeProviderProps>`
  color: ${props => props.theme.color.Foreground};
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6em;
  font-feature-settings: "kern", "liga", "clig", "calt";
  margin: 1rem auto 1em auto;
  font-family: ${props => props.theme.fonts.Body};
  width: ${props => props.theme.dimensions.contentWidth};
  max-width: 98%;
`;

export default Paragraph;
