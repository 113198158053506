import color from "color";
import React from "react";
import styled from "styled-components";

import { Location } from "@reach/router";
import { ThemeProviderProps } from "../theme";
import { HeaderSans } from "../theme/fonts";
import Link from "./elements/link";

const Home = styled(Link)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  color: ${(props: ThemeProviderProps) => props.theme.color.Foreground};
  background: ${(props: ThemeProviderProps) => props.theme.color.Background};
  text-decoration: none;

  font-weight: 800;
  font-size: 1rem;
  font-family: ${HeaderSans};

  border-radius: 30%;
  width: 2em;
  height: 2em;
  padding: 0.3em;
  text-align: center;

  &:hover {
    color: ${(props: ThemeProviderProps) =>
      color(props.theme.color.Background)
        .lighten(0.1)
        .hex()};
    background: ${(props: ThemeProviderProps) => props.theme.color.Purple};
  }
`;

const HomeLink = props => (
  <Location>
    {({ location }) =>
      location.pathname === "/" ? null : (
        <Home to={"/"} {...props}>
          OK
        </Home>
      )
    }
  </Location>
);

export { HomeLink };
