const System = `-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif`;

const Sans = `"IBM Plex Sans Condensed",${System}`;
const Serif = `"IBM Plex Serif",${System}`;

const HeaderSans = Sans;
const HeaderSerif = Serif;

const Body = `"IBM Plex Serif",${System}`;
const Monospace = `'IBM Plex Mono', 'SF Mono', Menlo, Monaco, 'Courier New', monospace`;

export { System, Sans, Serif, HeaderSans, HeaderSerif, Body, Monospace };
