import { MDXProvider } from "@mdx-js/tag";
import React from "react";
import styled from "styled-components";

import { MdxElements } from "./components/elements/mdx";
import { Theme } from "./theme";

const Container = styled.div`
  margin: 0 auto;
  max-width: 94%;
  width: 100%;
  min-height: 100vh;
`;

class App extends React.Component<any, any> {
  render() {
    return (
      <Theme>
        <Container>
          <MDXProvider components={MdxElements}>
            {this.props.children}
          </MDXProvider>
        </Container>
      </Theme>
    );
  }
}

const wrapRootElement = ({ element }) => {
  return <App>{element}</App>;
};
export { App, wrapRootElement };
