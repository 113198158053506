import styled from "styled-components";
import { ThemeProviderProps } from "@src/theme";

const Pre = styled.pre`
  padding: 1.2em 0.5em;
  width: 39rem;
  border-radius: 0.3rem;
  margin: 3em auto;
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Monospace};
  max-width: 98%;
  overflow: scroll;
`;

const Line = styled.div`
  margin-left: 0.5em;
  display: flex;
`;
const LineNumber = styled.div`
  padding-right: 1em;
  display: inline-block;
  text-align: right;
  color: ${(props: ThemeProviderProps) => props.theme.color.Selection};
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 3em;
`;
const CodeLine = styled.span``;

const HighlightedLineNumber = styled.div`
  padding-right: 1em;
  display: inline-block;
  text-align: right;
  color: ${(props: ThemeProviderProps) => props.theme.color.Comment};
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 3em;
`;

const HighlightedLine = styled.div`
  background-color: ${(props: ThemeProviderProps) =>
    props.theme.color.CurrentLine};
  border-left: 1em solid
    ${(props: ThemeProviderProps) => props.theme.color.Selection};
  margin-left: -0.5em;
  padding-left: 0;
  padding-right: 0.5em;
  margin-right: -0.5em;
  display: flex;
`;

const Meta = styled.div`
  margin: 0 2em;
  display: flex;
  justify-content: space-between;
`;

const Language = styled.div`
  position: relative;
  text-align: left;
  color: ${(props: ThemeProviderProps) => props.theme.color.Comment};
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Monospace};
  text-transform: uppercase;
  font-weight: bold;
`;

const Filename = styled.div`
  text-align: right;
  color: ${(props: ThemeProviderProps) => props.theme.color.Comment};
  font-family: ${(props: ThemeProviderProps) => props.theme.fonts.Monospace};
  font-weight: bold;
`;

export {
  Pre,
  Line,
  LineNumber,
  CodeLine,
  HighlightedLine,
  HighlightedLineNumber,
  Meta,
  Language,
  Filename,
};
