import { Link as GatsbyLink } from "gatsby";
import React from "react";
import styled from "styled-components";

import { ThemeProviderProps } from "@src/theme";

// Since DOM elements <a> cannot receive activeClassName
// and partiallyActive, destructure the prop here and
// pass it only to GatsbyLink
const Link = ({ children, to, activeClassName, partiallyActive, ...other }) => {
  // Tailor the following test to your environment.
  // This example assumes that any internal link (intended for Gatsby)
  // will start with exactly one slash, and that anything else is external.
  const internal = /^\/(?!\/)/.test(to);

  // Use Gatsby Link for internal links, and <a> for others
  if (internal) {
    return (
      <GatsbyLink
        to={to}
        activeClassName={activeClassName}
        partiallyActive={partiallyActive}
        {...other}
      >
        {children}
      </GatsbyLink>
    );
  }
  return (
    <a href={to} {...other}>
      {children}
    </a>
  );
};

const Anchor = styled(Link)<ThemeProviderProps>`
  color: ${props => props.theme.color.Purple};
  font-size: 1em;
  font-feature-settings: "kern", "liga", "clig", "calt";
  text-decoration-color: ${props => props.theme.color.Purple};
`;

const SubtleLink = styled(Anchor)<ThemeProviderProps>`
  color: ${props => props.theme.color.Comment};
  font-family: ${props => props.theme.fonts.Monospace};
  margin: 0 0.4rem;

  &:hover {
    color: ${props => props.theme.color.Foreground};
  }
`;

const NotSoSubtleLink = styled(Anchor)<ThemeProviderProps>`
  color: ${props => props.theme.color.Comment};
  font-family: ${props => props.theme.fonts.HeaderSans};
  margin: 0 0.4rem;

  &:hover {
    color: ${props => props.theme.color.Foreground};
  }
`;

export default Anchor;

export { Anchor, SubtleLink, NotSoSubtleLink };
