import React from "react";
import styled from "styled-components";

import { ThemeProviderProps } from "@src/theme";
import { MarkdownCode } from "./code/mdx";
import {
  Heading1,
  Heading2,
  Heading3,
  Heading4,
  Heading5,
  Heading6,
} from "./headings";
import HR from "./hr";
import Link from "./link";
import Paragraph from "./paragraph";

// Specfic element stylings for mdx pages

const PostHeading1 = styled(Heading1)<ThemeProviderProps>`
  width: ${props => props.theme.dimensions.contentWidth};
  max-width: 98%;
`;
const PostHeading2 = styled(Heading2)<ThemeProviderProps>`
  width: ${props => props.theme.dimensions.contentWidth};
  max-width: 98%;
`;
const PostHeading3 = styled(Heading3)<ThemeProviderProps>`
  width: ${props => props.theme.dimensions.contentWidth};
  max-width: 98%;
`;
const PostHeading4 = styled(Heading4)<ThemeProviderProps>`
  width: ${props => props.theme.dimensions.contentWidth};
  max-width: 98%;
`;
const PostHeading5 = styled(Heading5)<ThemeProviderProps>`
  width: ${props => props.theme.dimensions.contentWidth};
  max-width: 98%;
`;
const PostHeading6 = styled(Heading6)<ThemeProviderProps>`
  width: ${props => props.theme.dimensions.contentWidth};
  max-width: 98%;

  text-transform: uppercase;
  font-size: 1rem;
`;

const PostParagraph = styled(Paragraph)<ThemeProviderProps>``;

const List = styled.ul`
  color: ${props => props.theme.color.Foreground};
  width: ${props => props.theme.dimensions.contentWidth};
  max-width: 98%;
  font-size: 1em;
  font-weight: 400;
  line-height: 1.8em;
  font-feature-settings: "kern", "liga", "clig", "calt";
  margin: 0.4rem auto 1.6rem auto;
  font-family: ${props => props.theme.fonts.Body};
`;

const InlineCode = styled.span`
  color: ${props => props.theme.color.Purple};
  background-color: ${props => props.theme.color.Background};
  border: 1px solid ${props => props.theme.color.CurrentLine};
  font-family: ${props => props.theme.fonts.Monospace};
  padding: 0.2em 0.4em;
  font-size: 0.6em;
  border-radius: 3px;
  margin: 0 0.3em;
`;

const MdxElements = {
  h1: props => <PostHeading1 {...props} />,
  h2: props => <PostHeading2 {...props} />,
  h3: props => <PostHeading3 {...props} />,
  h4: props => <PostHeading4 {...props} />,
  h5: props => <PostHeading5 {...props} />,
  h6: props => <PostHeading6 {...props} />,
  p: props => <PostParagraph {...props} />,
  ul: props => <List {...props} />,
  ol: props => <List {...props} />,
  thematicBreak: props => <HR {...props} />,
  hr: props => <HR {...props} />,
  //blockquote
  //li
  //em
  //strong
  //table
  //tr
  //td
  //th
  //img
  //pre
  a: props => <Link {...props} to={props.href} />,
  code: props => <MarkdownCode {...props} />,
  inlineCode: props => <InlineCode {...props} />,
};

export { MdxElements };
